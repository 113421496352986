import React from "react";
import LayoutNew from "../components/LayoutNew";
import { Helmet } from "react-helmet";
import HaveQuestion from "./new-pages/common/HaveQuestion";
import MessageModal from "./new-pages/common/MessageModal";
import bubbleIcon from "../../static/images/speech-bubble.png";
import CommonOnboardingFAQs from "./new-pages/common/CommonOnboardingFAQs";
 

const WelcomePage = () => {

    const [messagePopup, setMessagePopup] = React.useState(false);  


    return (
        <LayoutNew>
            <Helmet>
                <title>Phone – Purple Cow Internet 💜🐄</title>
            </Helmet>

            <section className="tv-section1">
            <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">Welcome to Purple Cow!</h2>
                </div>
            <div>
                    <iframe width="1120" height="630" src="https://www.youtube.com/embed/RJb3aDMrScs?si=6RrEKmzsYo4pknTy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            </section>
            <section className="tv-section1 max-width1000">
                <h2>Step 1: We'll mail you the gear</h2>

            </section>
            <section className="tv-section1 max-width1000">
                <h2>Step 2: You plug it in</h2>

            </section>
            <section className="tv-section1 max-width1000">
                <h2>Step 3: You're online</h2>

            </section>

            <CommonOnboardingFAQs /> 

            <HaveQuestion closepopup={setMessagePopup}/>
            <div className="contact-us-parent">
                <div className="contact-click-box" onClick={() => setMessagePopup(true)}>
                    <img src={bubbleIcon} alt="bubble" className="bubble-icon" />
                </div>
            </div>
            {messagePopup ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
        </LayoutNew >
    )
}

export default WelcomePage